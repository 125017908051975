import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Assignment } from 'src/app/shared/models/Entity/Assignment';
import { Document } from 'src/app/shared/models/Entity/Document';
import { Shipping } from 'src/app/shared/models/Entity/Shipping';
import { AssignmentService } from 'src/app/shared/service/assignment.service';
import { ShippingService } from 'src/app/shared/service/shipping.service';

@Component({
  selector: 'app-accept-as-customer',
  templateUrl: './accept-as-customer.component.html',
  styleUrls: ['./accept-as-customer.component.css']
})
export class AcceptAsCustomerComponent implements OnInit {
  assignment: Assignment;
  shippings: Shipping[];
  hashId: number;
  document: string;
  panelOpenState = false;
  public innerWidth = 0;
  public defaultSidebar = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';
  fullsum: number;
  status: string;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private assignmentService: AssignmentService,
              private shippingsService: ShippingService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.status = 'Loading';
    this.route.params.subscribe((params: Params) => {
      this.hashId = params.id;
      this.handleSidebar();
      this.loadAssignmentByHashId(this.hashId);
      this.loadShippingsByHashId(this.hashId);
    });
    this.status = 'Loaded';
  }

  loadAssignmentByHashId(hashId: number): void {
    this.assignmentService.getByHashId(hashId).subscribe(data => {
      this.assignment = data;
    });
  }

  loadShippingsByHashId(hashId: number): void {
    this.shippingsService.getByHashId(hashId).subscribe(data => {
      this.shippings = data;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  getFullSum(): string {
    const fullsum = this.assignment?.Price * 1.19;
    return fullsum.toFixed(2);
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }

  acceptAssignmentByCustomer(assignment: Assignment): void {
    this.assignmentService.acceptAssignmentByCustomer(assignment);
    location.reload();
  }

  declineAssignmentByCustomer(assignment: Assignment): void {
    this.assignmentService.declineAssignmentByCustomer(assignment);
    location.reload();
  }
}
