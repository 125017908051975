<div class="signin-content" *ngIf="loginBoolean &&!registerBoolean">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form">
        <p>Please login to continue</p>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Email" formControlName="email" required>
          <mat-error>
            Bitte gültige Email-Addresse eingeben
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error>
            Bitte gültiges Passwort eingeben
          </mat-error>
        </mat-form-field>
        <button mat-raised-button (click)="submit()" color="primary">Login</button>
        <br>
        <button mat-raised-button color="primary" style="margin-top: 15px;" (click)="registerBool()">Register</button>
      </form>
      <div *ngIf="errorStatus == false" style="border-top: 10px;">
        <mat-error>{{errorMessage}}</mat-error>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="register-content" *ngIf="!loginBoolean && registerBoolean">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="registerForm">
        <p>Bitte regestrieren um fortzufahren</p>
        <mat-form-field appearance="fill">
          <mat-label>Geschlecht</mat-label>
          <mat-select formControlName="sex">
            <mat-option value="1">Männlich</mat-option>
            <mat-option value="2">Weiblich</mat-option>
            <mat-option value="3">Divers</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Vorname</mat-label>
          <input matInput formControlName="firstname">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Nachname</mat-label>
          <input matInput formControlName="lastname">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Firma</mat-label>
          <input matInput formControlName="company">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Passwort</mat-label>
          <input type="password" matInput formControlName="password">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Passwort wiederholen</mat-label>
          <input type="password" matInput formControlName="repassword">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Telefonnummer</mat-label>
          <input matInput formControlName="phonenumber">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Straße</mat-label>
          <input matInput formControlName="billStreet">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>No</mat-label>
          <input matInput formControlName="billNo">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Postleitzahl</mat-label>
          <input matInput formControlName="billZipCode">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Stadt</mat-label>
          <input matInput formControlName="billCity">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Region</mat-label>
          <input matInput formControlName="billState">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Land</mat-label>
          <input matInput formControlName="billCountry">
        </mat-form-field>
        <br>
        <button mat-raised-button (click)="register()" color="primary">Register</button>
        <br>
        <button mat-raised-button color="primary" style="margin-top: 15px;" (click)="loginBool()">Login</button>
      </form>
      <div style="margin-top: 25px; color: red; font-weight: bold;">
        {{validationError}}
      </div>
      <div *ngIf="errorStatus == false" style="border-top: 10px;">
        <mat-error>{{errorMessage}}</mat-error>
      </div>
    </mat-card-content>
  </mat-card>
</div>
