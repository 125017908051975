<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <!-- <li class="nav-item d-none d-md-block d-lg-none">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li> -->

</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/users/user.png" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu user-dd" ngbDropdownMenu>
            <a (click)="openMyProfilDialog()" class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> Mein Profil</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <div class="dropdown-divider"></div>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
