import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyprofilComponent } from 'src/app/component/myprofil/myprofil.component';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent implements OnInit {

  constructor(private dialog: MatDialog, private userService: UserService,
              private dialogRef: MatDialogRef<LogoutDialogComponent>) { }

  ngOnInit(): void {
  }

  openMyProfilDialog(): void {
    const dialogRef = this.dialog.open(MyprofilComponent, {
      backdropClass: 'bdrop',
      autoFocus: false,
      height: '800px'
    });
  }

  Logout(): void {
    localStorage.clear();
    this.userService.logout();
  }

  Close(): void {
    this.dialogRef.close();
  }
}
