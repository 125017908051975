<div *ngIf="status === 'Loaded'">
<div id="main-wrapper" dir="ltr" data-theme="light" data-layout="vertical"
     data-sidebar-position="absolute" data-header-position="fixed"
    data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->
    <header class="topbar" data-navbarbg="skin6">
        <nav class="d-flex top-navbar navbar-expand-md navbar-light">
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
                <app-navigation class="w-100"></app-navigation>
            </div>
        </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <app-breadcrumb></app-breadcrumb>
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
          <div *ngIf="assignment?.AcceptByCustomer === false && assignment?.Declined === false">
            <div class="card">
            <div class="card-body">
              <h4 class="font-medium font-16 text-uppercase" style="border-bottom: 1px solid #8898aa; text-align: center;">{{assignment.Company}} | {{assignment?.Firstname}} {{assignment?.Lastname}}</h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Auftragsdetails</h4>
            <table style="border-collapse: separate; border-spacing: 0 6px; margin-left: auto; margin-right: auto;">
              <tr>
                <td style="width: 450px;"><h4 class="font-medium font-12 text-uppercase">Kunde:</h4></td>
                <td ><h4 class="font-medium font-12 text-uppercase">Rechnungsanschrift:</h4></td>
              </tr>
              <tr>
                <td><p>{{assignment?.Firstname}} {{assignment?.Lastname}}</p></td>
                <td><p>{{assignment?.BillStreet}} {{assignment?.BillNo}}</p></td>
              </tr>
              <tr>
                <td><p>{{assignment?.Email}}</p></td>
                <td><p>{{assignment?.BillZipCode}} {{assignment?.BillCity}}</p></td>
              </tr>
              <tr>
                <td>
                  <p>{{assignment?.Company}}</p>
                </td>
                <td><p>{{assignment?.BillState}}</p></td>
              </tr>
              <tr>
                <td><p>UstId: {{assignment?.UstId}}</p></td>
                <td><p>{{assignment?.BillCountry}}</p></td>
              </tr>
              <tr>
                <td>Telefon: {{assignment?.Phonenumber}}</td>
                <td></td>
              </tr>
            </table>
            <div *ngIf="assignment?.Equipment !== ''" style="margin-top: 15px">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Weitere Informationen</h4>
              <p style="text-align: center;">{{assignment?.Equipment}}</p>
            </div>
            <div *ngIf="assignment?.Equipment === ''" style="margin-top: 15px">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Weitere Infomationen</h4>
              <p style="text-align: center;">Keine Informationen wurden angegeben</p>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Versandgut</h4>
              <div *ngFor="let item of shippings" style="margin-top: 5px;">
                <mat-accordion>
                  <mat-expansion-panel (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{item.ShippingGoods}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="table-responsive">
                    <table class="table v-middle nowrap">
                      <tbody>
                        <tr>
                          <td><div style="font-weight: bold; color: #666; float: left;">Marke & Typ</div><div style="float: right;">{{item?.ShippingGoods}}</div></td>
                        </tr>
                        <tr>
                          <td><div style="font-weight: bold; color: #666; float: left;">Identifikationsnummer</div><div style="float: right;">{{item?.IdentificationNumber}}</div></td>
                        </tr>
                        <h4 style="text-align:center; margin-top: 30px; color: rgba(0, 0, 0, 0.87);">Abholadresse</h4>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Straße & No</div><div style="float: right;">{{item?.AbholStreet}} {{item?.AbholNo}}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Plz & Stadt</div><div style="float: right;">{{item?.AbholZipCode}} {{item?.AbholCity}}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Land</div><div style="float: right;">{{item?.AbholCountry}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.AbholContactCompany !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Firma</div><div style="float: right;">{{item?.AbholContactCompany}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.AbholContactFullname !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Person</div><div style="float: right;">{{item?.AbholContactFullname}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.AbholContactEmail !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Email</div><div style="float: right;">{{item?.AbholContactEmail}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.AbholContactPhonenumber !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Telefonnummer</div><div style="float: right;">{{item?.AbholContactPhonenumber}}</div>
                          </td>
                        </tr>
                        <h4 style="text-align:center; margin-top: 30px; color: rgba(0, 0, 0, 0.87);">Lieferadresse</h4>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Straße & No</div><div style="float: right;">{{item?.LieferStreet}} {{item?.LieferNo}}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Plz & Stadt</div><div style="float: right;">{{item?.LieferZipCode}} {{item?.LieferCity}}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Land</div><div style="float: right;">{{item?.LieferCountry}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.LieferContactCompany !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Firma</div><div style="float: right;">{{item?.LieferContactCompany}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.LieferContactFullname !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Person</div><div style="float: right;">{{item?.LieferContactFullname}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.LieferContactEmail !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Email</div><div style="float: right;">{{item?.LieferContactEmail}}</div>
                          </td>
                        </tr>
                        <tr *ngIf="item?.LieferContactPhonenumber !== ''">
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Kontakt Telefonnummer</div><div style="float: right;">{{item?.LieferContactPhonenumber}}</div>
                          </td>
                        </tr>
                        <h4 style="text-align:center; margin-top: 30px; color: rgba(0, 0, 0, 0.87);">Aktion</h4>
                        <tr>
                          <td>
                            <div style="font-weight: bold; color: #666; float: left;">Wunschdatum</div><div style="float: right;">{{item?.ShippingDate | date: 'dd-MM-yyyy'}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Preisvorschlag</h4>
              <p style="text-align: center;">bezüglich Ihrer Anfrage können wir Ihnen dazu folgendes Angebot unterbreiten: <b>{{assignment.Price}} €</b> - zuzüglich Mehrwertsteuer</p>
              <div style="float: right; margin-right: 10px;">
                <button (click)="acceptAssignmentByCustomer(assignment)" mat-stroked-button style="color: green;">Auftrag kostenpflichtig bestellen</button>
              </div>
              <div style="float: right; margin-right: 10px;">
                <button (click)="declineAssignmentByCustomer(assignment)" mat-stroked-button style="color: red;">Auftrag ablehnen</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="assignment?.AcceptByCustomer === true && assignment?.Declined === false">
          <div class="card">
            <div class="card-body">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Auftrag wurde angenommen!</h4>
            </div>
          </div>
        </div>
        <div *ngIf="assignment?.AcceptByCustomer === false && assignment?.Declined === true">
          <div class="card">
            <div class="card-body">
              <h4 class="font-medium font-16 text-uppercase" style="text-align: center;">Auftrag wurde abgelehnt. Ihnen wird nach Absprache ein neues Angebot zugesendet!</h4>
            </div>
          </div>
        </div>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
</div>
