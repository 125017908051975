import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'UI Components',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/component/assignment',
    title: 'Aufträge',
    icon: 'mdi mdi-clipboard-text',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/calendar',
    title: 'Kalender',
    icon: 'mdi mdi-calendar',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/servicepackage',
    title: 'Leistungen',
    icon: 'mdi mdi-domain',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/user',
    title: 'Benutzer',
    icon: 'mdi mdi-account',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/price-list',
    title: 'Preis-Liste',
    icon: 'mdi mdi-currency-eur',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/form',
    title: 'Neuer Auftrag',
    icon: 'mdi mdi-database-plus',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: 'component/summary-assignments',
    title: 'Zusammenfassung',
    icon: 'mdi mdi-book-plus',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/impressum',
    title: 'Impressum',
    icon: 'mdi mdi-information-variant',
    class: '',
    extralink: false,
    submenu: []
  },
];

export const ROUTEUser: RouteInfo [] = [
  {
    path: '/component/assignment',
    title: 'Aufträge',
    icon: 'mdi mdi-clipboard-text',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/price-list',
    title: 'Preis-Liste',
    icon: 'mdi mdi-currency-eur',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/form',
    title: 'Neuer Auftrag',
    icon: 'mdi mdi-database-plus',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'UI Components',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/component/impressum',
    title: 'Impressum',
    icon: 'mdi mdi-information-variant',
    class: '',
    extralink: false,
    submenu: []
  },
];

export const ROUTEGuest: RouteInfo [] = [
  {
    path: '/component/price-list',
    title: 'Preis-Liste',
    icon: 'mdi mdi-currency-eur',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/form',
    title: 'Neuer Auftrag',
    icon: 'mdi mdi-database-plus',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/impressum',
    title: 'Impressum',
    icon: 'mdi mdi-information-variant',
    class: '',
    extralink: false,
    submenu: []
  }
]
