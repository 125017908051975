import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChangeAssignmentStatusViewModel } from '../models/ChangeAssignmentStatusViewModel';
import { EditFurtherInformationViewModel } from '../models/EditFurtherInformationViewModel';
import { Assignment } from '../models/Entity/Assignment';
import { UpdateAssignmentDetailsViewModel } from '../models/UpdateAssignmentDetailsViewModel';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {
  openComponentList: Assignment[] = [];
  draftList: Assignment[] = [];
  openList: Assignment[];
  closedList: Assignment[];
  allDocuments: Document[];
  assignmentCountByUserId: number;
  assignmentById: Assignment;
  baseUrl: string;
  openAssignments: Assignment[];
  draftAssignents: Assignment[];
  closedAssignments: Assignment[];
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + environment.ApiKey,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
  };
  constructor(private http: HttpClient) {
    this.baseUrl = environment.BaseUrl;
  }

  async getDraftAssignments(): Promise<void> {
    await this.http.get<Assignment[]>(this.baseUrl + 'Assignment/draft', this.httpOptions)
    .subscribe((data => {
      this.draftList = data as Assignment[];
    }));
  }

  async open(): Promise<void> {
    await this.http.get(this.baseUrl + 'Assignment/allOpen', this.httpOptions)
    .subscribe((data) => {
      this.openList = data as Assignment[];
    });
  }

  openAssignmentsReturn(): Observable<Assignment[]> {
    return this.http.get<Assignment[]>(this.baseUrl + 'Assignment/allOpen', this.httpOptions);
  }

  async openUserAssignments(userId: number): Promise<void> {
    await this.http.get(this.baseUrl + 'Assignment/allOpenUserAssignments/' + userId, this.httpOptions)
    .subscribe((data) => {
      this.openAssignments = data as Assignment[];
    });
  }

  async draftUserAssignments(userId: number): Promise<void> {
    await this.http.get(this.baseUrl + 'Assignment/allDraftUserAssignments/' + userId, this.httpOptions)
    .subscribe((data) => {
      this.draftAssignents = data as Assignment[];
    });
  }

  async closedUserAssignments(userId: number): Promise<void> {
    await this.http.get(this.baseUrl + 'Assignment/allClosedUserAssignments/' + userId, this.httpOptions)
    .subscribe((data) => {
      this.closedAssignments = data as Assignment[];
    });
  }

  async editFurtherInformationDetails(model: EditFurtherInformationViewModel): Promise<void> {
    await this.http.post(this.baseUrl + 'Assignment/editFurtherInformation', model, this.httpOptions).toPromise();
  }

  async updateAssignmentDetails(model: UpdateAssignmentDetailsViewModel): Promise<void> {
    await this.http.put(this.baseUrl + 'Assignment/updateAssignmentDetails', model, this.httpOptions).toPromise();
  }

  async closed(): Promise<void> {
    await this.http.get(this.baseUrl + 'Assignment/allClosed', this.httpOptions)
    .subscribe((data) => {
      this.closedList = data as Assignment[];
    });
  }

  async getById(assignmentId: number): Promise<void> {
    await this.http.get<Assignment>(this.baseUrl + 'Assignment/getById/' + assignmentId, this.httpOptions)
    .subscribe((data) => {
      this.assignmentById = data as Assignment;
      console.log(this.assignmentById);
    });
  }

  getByHashId(hashId: number): Observable<Assignment> {
    return this.http.get<Assignment>(this.baseUrl + 'Unauthorized/hashaccept/' + hashId);
  }

  acceptAssignmentByCustomer(assignment: Assignment): void {
    this.http.post(this.baseUrl + 'Unauthorized/acceptAssignmentByCustomer', assignment).toPromise();
  }

  declineAssignmentByCustomer(assignment: Assignment): void {
    this.http.post(this.baseUrl + 'Unauthorized/declineAssignmentByCustomer', assignment).toPromise();
  }

  getAssignmentDocuments(assignmentId: number): Observable<string> {
    return this.http.get<string>(this.baseUrl + 'Assignment/getAssignmentDocuments/' + assignmentId, this.httpOptions);
  }

  async close(assignmentId: number): Promise<void> {
    const model = new ChangeAssignmentStatusViewModel;
    model.AssignmentId = assignmentId;
    await this.http.post<Assignment>(this.baseUrl + 'Assignment/close', model, this.httpOptions).toPromise();
  }

  async delete(assignmentId: number): Promise<void> {
    await this.http.delete<Assignment>(this.baseUrl + 'Assignment/delete/' + assignmentId, this.httpOptions).toPromise();
  }

  async accept(assignmentId: number): Promise<void> {
    const model = new ChangeAssignmentStatusViewModel;
    model.AssignmentId = assignmentId;
    await this.http.post(this.baseUrl + 'Assignment/accept', model, this.httpOptions).toPromise();
  }

  acceptOffer(assignmentId: number): void {
    const model = new ChangeAssignmentStatusViewModel;
    model.AssignmentId = assignmentId;
    this.http.post(this.baseUrl + 'Assignment/acceptOffer', model, this.httpOptions).toPromise();
  }

  async getAssignmentCountByUserId(userId: number) {
    await this.http.get(this.baseUrl + 'Assignment/assignmentCountByUserId/' + userId, this.httpOptions).subscribe(x => {
      this.assignmentCountByUserId = x as number;
    });
  }

  async getAllDocuments(): Promise<void> {
    await this.http.get(this.baseUrl + 'Assignment/getAllDocuments', this.httpOptions).subscribe(x => {
      this.allDocuments = x as Document[];
    });
  }
}
