export enum AddressTypeEnum {
  BillAddress = 1,
  PickupAddress = 2
}

export enum RoleTypeEnum {
  User = 1,
  Admin = 2,
  SuperAdmin = 3,
  Guest = 4
}
