import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MyprofilComponent } from 'src/app/component/myprofil/myprofil.component';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;

  constructor(private userService: UserService, private dialog: MatDialog) {}

  ngOnInit(): void {
  }

  openMyProfilDialog(): void {
    const dialogRef = this.dialog.open(MyprofilComponent, {
      backdropClass: 'bdrop',
      height: '800px'
    });
  }

  logout(): void {
    localStorage.clear();
    this.userService.logout();
  }
}
