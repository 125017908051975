import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MyprofilComponent } from 'src/app/component/myprofil/myprofil.component';
import { UserService } from 'src/app/shared/service/user.service';
import { LogoutDialogComponent } from './dialog/logout-dialog/logout-dialog.component';
@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  public isLoggedIn: boolean;
  public isAdmin: boolean;
  public isUser: boolean;

  constructor(public router: Router, private userService: UserService,
              private dialog: MatDialog) {}

  public innerWidth = 0;
  public defaultSidebar = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/starter']);
    }
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();
    this.isLoggedIn = this.userService.isLoggedIn;
    this.isAdmin = this.userService.isAdmin
    this.isUser = this.userService.IsUser
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  openDialog(): void {
    this.dialog.open(LogoutDialogComponent);
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }

  closeSideBarHandy() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 742) {
      this.showMobileMenu = false;
    }
  }
}
