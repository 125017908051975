import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Person } from 'src/app/shared/models/Entity/Person';
import { AddressTypeEnum } from 'src/app/shared/models/Enum';
import { SavedData } from 'src/app/shared/models/SavedData';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-myprofil',
  templateUrl: './myprofil.component.html',
  styleUrls: ['./myprofil.component.css']
})

export class MyprofilComponent implements OnInit {
  myprofilForm: FormGroup;
  form: FormGroup;
  public myProfilePageState: string;
  savedData: SavedData;
  constructor(public userService: UserService, private router: Router, private _formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<MyprofilComponent>) {
   this.form = this._formBuilder.group({
      firstname: [''],
      lastname: [''],
      company: [''],
      email: [''],
      phonenumber: [''],
      billStreet: [''],
      billNo: [''],
      billZipCode: [''],
      billCity: [''],
      billState: [''],
      billCountry: [''],
     })

                this.myprofilForm = this._formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', Validators.required],
      billStreet: ['', Validators.required],
      billNo: ['', Validators.required],
      billZipCode: ['', Validators.required],
      billCity: ['', Validators.required],
      billState: [''],
      billCountry: ['', Validators.required],
    });
   }

  ngOnInit(): void {
    this.start();
  }

  async start(): Promise<void> {
    this.myProfilePageState = 'Loading';
    if (this.userService.isLoggedIn) {
      const id = Number(localStorage.getItem('id'));
      await this.userService.GetById(id);
    } else {
      this.router.navigate(['component']);
    }
    this.myProfilePageState = 'Loaded';
  }

  async submitTest(): Promise<void> {
    console.log(this.savedData);
    const data = {
      Lastname: this.form.value.lastname,
      UserId: Number(localStorage.getItem('id')),
      Firstname: this.form.value.firstname,
      Company: this.form.value.company,
      Email: this.form.value.email,
      Phonenumber: this.form.value.phonenumber,
      BillStreet: this.form.value.billStreet,
      BillNo: this.form.value.billNo,
      BillZipCode: this.form.value.billZipCode,
      BillCity: this.form.value.billCity,
      BillState: this.form.value.billState,
      BillCountry: this.form.value.billCountry
    };
    await this.userService.editMyProfil(data);
    location.reload();
  }

  Close(): void {
    this.dialogRef.close();
  }

}
