import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTEGuest, ROUTES, ROUTEUser } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public isAdmin: boolean;
  public isUser: boolean;
  public isGuest: boolean;
  public sidebarnavItems: RouteInfo [] = [];
  // this is for the open close
  addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

  // End open close
  ngOnInit() {
    this.isAdmin = this.userService.isAdmin
    this.isUser = this.userService.IsUser
    this.isGuest = this.userService.IsGuest
    console.log("Admin", this.isAdmin);
    console.log("User", this.isUser);
    console.log(this.isGuest);

    if (this.isAdmin) {
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
      } else if (this.isUser) {
      this.sidebarnavItems = ROUTEUser.filter(sidebarnavItem => sidebarnavItem);
      }

      else{
      this.sidebarnavItems = ROUTEGuest.filter(sidebarnavItem => sidebarnavItem);
      this.router.navigate(['/component/form']);
    }
  }
}
