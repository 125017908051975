import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Shipping, ShippingViewModel } from '../models/Entity/Shipping';
import { UpdateShippingPrice } from '../models/UpdateShippingPrice';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  model: Shipping;
  baseUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + environment.ApiKey,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
  };

  constructor(private http: HttpClient) {
    this.baseUrl = environment.BaseUrl;
  }

  async updateShipping(model: ShippingViewModel): Promise<void> {
    await this.http.put(this.baseUrl + 'Shipping/update', model, this.httpOptions).toPromise();
  }

  delete(shippmentId: number): void {
    this.http.delete(this.baseUrl + 'Shipping/delete/' + shippmentId, this.httpOptions).toPromise();
  }

  getById(shippingId: number): void {
    this.http.get<Shipping>(this.baseUrl + 'Shipping/getById/' + shippingId, this.httpOptions).subscribe(data => {
      this.model = data;
    })
  }

  getByHashId(hashId: number): Observable<Shipping[]> {
    return this.http.get<Shipping[]>(this.baseUrl + 'Unauthorized/hashshipping/' + hashId);
  }

  async UpdateShipping(model: UpdateShippingPrice): Promise<void> {
    await this.http.put(this.baseUrl + 'Shipping/updateShippingPrice', model, this.httpOptions).toPromise();
  }

  earliestShippingDateForAssignment(assignmentId: number): Observable<string> {
    return this.http.get<string>(this.baseUrl + 'earliestShippingDateForAssignment/' + assignmentId);
  }

  async storedGoodStatus(model: Shipping): Promise<void> {
    await this.http.post(this.baseUrl + "Shipping/storedGood", model, this.httpOptions).toPromise();
  }

  async onTheWay(model: Shipping): Promise<void> {
    await this.http.post(this.baseUrl + "Shipping/goodOnTheWay", model, this.httpOptions).toPromise();
  }

  async pickedUp(model: Shipping): Promise<void> {
    await this.http.post(this.baseUrl + "Shipping/pickedUp", model, this.httpOptions).toPromise();
  }

  async deliverToSender(model: Shipping): Promise<void> {
    await this.http.post(this.baseUrl + "Shipping/deliverToSender", model, this.httpOptions).toPromise();
  }

  async delivered(model: Shipping): Promise<void> {
    await this.http.post(this.baseUrl + "Shipping/goodDelivered", model, this.httpOptions).toPromise();
  }
}
