import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { trackByHourSegment } from 'angular-calendar/modules/common/util';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Person } from '../models/Entity/Person';
import { Role } from '../models/Entity/Role';
import { RoleTypeEnum } from '../models/Enum';
import { LoginViewModel } from '../models/LoginViewModel';
import { PersonRegisterRequestViewModel } from '../models/PersonRegisterRequestViewModel';
import { UpdateMyProfileViewModel } from '../models/UpdateMyProfileViewModel';
import { UpdateRoleViewModel } from '../models/UpdateRoleViewModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl: string;
  modelById: Person;
  roleId: number;
  list: Person[];
  roleList: Role[];
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + environment.ApiKey,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
  };

  constructor(private http: HttpClient, private router: Router) {
    this.baseUrl = environment.BaseUrl;
  }

  async GetById(id: number): Promise<void> {
    await this.http.get<Person>(this.baseUrl + `User/getbyid/` + id, this.httpOptions).subscribe(data => {
        this.modelById = data;
    });
  }

  async Register(model: PersonRegisterRequestViewModel): Promise<void> {
    await this.http.post(this.baseUrl + 'Auth/register', model, this.httpOptions).toPromise();
  }

  async editMyProfil(model: UpdateMyProfileViewModel): Promise<void> {
    await this.http.post<Person>(this.baseUrl + 'User/editmyprofil/', model, this.httpOptions).toPromise();
  }

  CurrentUser(): Observable<Person> {
    return this.http.get<Person>(this.baseUrl + "User/CurrentUser/" + Number(localStorage.getItem('id')), this.httpOptions);
  }

  getRole(): void {
    this.CurrentUser().subscribe(data => {
      console.log(data);
      this.roleId = data.RoleId as number;
    })
  }

  async Login(data: LoginViewModel): Promise<void> {
    await this.http.post<{User: Person, Token: string}>(this.baseUrl + 'Auth/login', data, this.httpOptions)
    .pipe(tap(res => {
      const token = localStorage.setItem('token', res.Token.toString());
      const id = localStorage.setItem('id', res.User.Id.toString());
      const roleId = localStorage.setItem('roleId', res.User.RoleId.toString());
      if (id !== null && token !== null) {
        location.reload();
      }
    }))
    .toPromise();
  }

  async getAllRoles(): Promise<void> {
    await this.http.get(this.baseUrl + 'User/GetAllRoles', this.httpOptions).subscribe(x => {
      this.roleList = x as Role[];
    });
  }

  updateRoles(model: UpdateRoleViewModel): void {
    this.http.post(this.baseUrl + 'User/UpdateRole', model, this.httpOptions).toPromise();
  }

  get isLoggedIn(): boolean {
    const authToken = localStorage.getItem('token');
    return (authToken !== null) ? true : false;
  }

  get IsGuest(): boolean {
      if (Number(localStorage.getItem('roleId')) === RoleTypeEnum.Guest) {
        return true;
      } else {
        return false;
      }
  }

  get isAdmin(): boolean {
    if (Number(localStorage.getItem('roleId')) === RoleTypeEnum.Admin) {
      return true;
    } else {
      return false;
    }
  }

  get IsUser(): boolean {
    if (Number(localStorage.getItem('roleId')) === RoleTypeEnum.User) {
      return true;
    } else {
      return false;
    }
  }

  logout(): void {
    const removeToken = localStorage.removeItem('token');
    const id = localStorage.removeItem('id');
      const roleId = localStorage.removeItem('roleId');
    window.location.reload();
  }

  async getAll(): Promise<void> {
    await this.http.get<Person[]>(this.baseUrl + 'User/all', this.httpOptions).subscribe(data => {
      this.list = data as Person[];
    });
  }
}
