<div *ngIf="myProfilePageState === 'Loaded'">
<form [formGroup]="form">
  <div class="grid_items">
    <div class="grid_item">
      <h3>Kontaktdetails</h3>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Vorname (Ansprechpartner)</mat-label>
          <input matInput formControlName="firstname" [ngModel]="userService.modelById?.Firstname">
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Nachname (Ansprechpartner)</mat-label>
          <input matInput formControlName="lastname" [ngModel]="userService.modelById?.Lastname">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Firma</mat-label>
          <input matInput formControlName="company" [ngModel]="userService.modelById?.Company">
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Email-Addresse</mat-label>
          <input matInput formControlName="email" [ngModel]="userService.modelById?.Email">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Telefonnummer</mat-label>
          <input matInput formControlName="phonenumber" [ngModel]="userService.modelById?.Phonenumber">
        </mat-form-field>
      </div>
    </div>
    <div class="grid_item">
      <h3>Rechnungsanschrift</h3>
      <div class="space">
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Straße</mat-label>
          <input matInput type="text" formControlName="billStreet" [ngModel]="userService.modelById?.BillStreet">
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>No.</mat-label>
          <input matInput type="text" formControlName="billNo" [ngModel]="userService.modelById?.BillNo">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="billZipCode" [ngModel]="userService.modelById?.BillZipCode">
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="billCity" [ngModel]="userService.modelById?.BillCity">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>State / Province / Region</mat-label>
          <input matInput type="text" formControlName="billState" [ngModel]="userService.modelById?.BillState">
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Country</mat-label>
          <input matInput type="text" formControlName="billCountry" [ngModel]="userService.modelById?.BillCountry">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div style="margin-top: 20px; padding: 5px;">
    <button style="float: right;" (click)="submitTest()" mat-stroked-button>Speichern</button>
    <button style="margin-right: 10px; float: right;" (click)="Close()" mat-stroked-button>Abbrechen</button>
  </div>
</form>
</div>
