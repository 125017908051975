<div style="justify-content: center; display: flex;">
  <button mat-stroked-button (click)="openMyProfilDialog()">Mein Profil</button>
</div>
<div style="justify-content: center; display: flex; margin-top: 20px;">
  <button mat-stroked-button (click)="Logout()">Logout</button>
</div>
<div style="justify-content: center; display: flex;margin-top: 20px;">
  <button mat-stroked-button (click)="Close()">Close</button>
</div>

