import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AllValidationErrors, FormGroupControls } from 'src/app/component/form/form.component';
import { Person } from '../models/Entity/Person';
import { RoleTypeEnum } from '../models/Enum';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  registerForm: FormGroup;
  errorMessage: string;
  errorStatus: boolean;
  registerBoolean = false;
  loginBoolean = true;
  validationError: string;
  constructor(private fb: FormBuilder, private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.registerForm = this.fb.group({
      sex: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      company: [''],
      email: ['', Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required],
      phonenumber: ['', Validators.required],
      billStreet: ['', Validators.required],
      billNo: ['', Validators.required],
      billZipCode: ['', Validators.required],
      billCity: ['', Validators.required],
      billState: [''],
      billCountry: ['', Validators.required]
    });
  }

  async register(): Promise<void> {
    const check = this.check();
    if(check) {
      this.errorStatus = true;
      const post = {
        Sex: this.registerForm.value.sex,
        Firstname: this.registerForm.value.firstname,
        Lastname: this.registerForm.value.lastname,
        Company: this.registerForm.value.company,
        Email: this.registerForm.value.email,
        Password: this.registerForm.value.password,
        RePassword: this.registerForm.value.repassword,
        Phonenumber: this.registerForm.value.phonenumber,
        BillStreet: this.registerForm.value.billStreet,
        BillNo: this.registerForm.value.billNo,
        BillZipCode: this.registerForm.value.billZipCode,
        BillCity: this.registerForm.value.billCity,
        BillState: this.registerForm.value.billState,
        BillCountry: this.registerForm.value.billCountry
      };
      await this.userService.Register(post).catch((ex: HttpErrorResponse) => {
        this.errorStatus = false;
        console.error(ex.error.error);
        this.errorMessage = ex.error.error;
      });
      if (this.errorStatus = true) {
        const login = {
          Email: this.registerForm.value.email,
          Password: this.registerForm.value.password
        }
        await this.userService.Login(login).catch((ex: HttpErrorResponse) => {
          this.errorStatus = false;
          console.error(ex.error.error);
          this.errorMessage = ex.error.error;
        });
      }
    }
  }

  getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    Object.keys(controls).forEach(key => {
      const control = controls[ key ];
      if (control instanceof FormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors = controls[ key ].errors as ValidationErrors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: key,
            error_name: keyError,
            error_value: controlErrors[ keyError ]
          });
        });
      }
    });
    return errors;
  }

  check(): boolean {
    const errors = this.getFormValidationErrors(this.registerForm.controls);
      if (errors.length > 0) {
        const errorLength = errors.length;
        let text;
        text = `${errorLength} benötigte Felder müssen noch ausgefüllt werden, sie sind Rot unterlegt!`;
        this.validationError = text;
        return false;
      }
    return true;
  }

  async submit(): Promise<void> {
    this.errorStatus = true;
    const post = {
      Email: this.form.value.email,
      Password: this.form.value.password
    };
    await this.userService.Login(post).catch((ex: HttpErrorResponse) => {
      this.errorStatus = false;
      console.error(ex.error.error);
      this.errorMessage = ex.error.error;
    });
  }

  registerBool(): void {
    this.loginBoolean = false;
    this.registerBoolean = true;
  }

  loginBool(): void {
    this.registerBoolean = false;
    this.loginBoolean = true;
  }
}
